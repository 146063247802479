<template>
  <v-data-table
    dense
    disable-sort
    :headers="headers"
    :items="$store.getters.getWMSRECEIVINGSkuTableData.data"
    :options.sync="options"
    :server-items-length="$store.getters.getWMSRECEIVINGSkuTableData.total"
    :loading="loading"
    class="elevation-0"
    mobile-breakpoint="300"
    item-class="text-center"
    :footer-props="{ 'items-per-page-options': [50, 40, 30, 20] }"
  >
    <template
      v-slot:item.sku_component="{ value }"
      v-if="$store.getters.getWMSRECEIVINGReceivingType !== 3"
    >
      <div class="max-w-250px d-flex align-center">
        <ShowImage :item="value" />

        <div class="d-flex justify-content-start flex-column">
          <span class="font-size-lg text-hover-info mb-1">{{
            value.text1
          }}</span>
          <span class="text-dark-25 text-wrap font-size-sm">{{
            value.text2
          }}</span>
        </div>
      </div>
    </template>

    <template v-slot:item.status="{ value }">
      <span
        class="text-center badge font-size-sm my-2 py-2 px-3"
        :class="[
          value === 'Created'
            ? 'badge-light-success'
            : value === 'Received'
            ? 'badge-light-primary'
            : 'badge-light-warning',
        ]"
      >
        {{ value }}
      </span>
    </template>
  </v-data-table>
</template>

<script>
import ShowImage from "@/own/components/warehouseManagement/receiving/ShowImage";
import ApiService from "@/core/services/api.service";
import { SET_SKUS_TABLE_DATA } from "@/core/services/store/receiving.module";
export default {
  name: "SKUSDataTable",
  components: { ShowImage },
  data: () => ({
    // total: 0,
    options: {},
    loading: true,
    // items: [],
  }),
  computed: {
    headers() {
      return this.$store.getters.getWMSRECEIVINGSKUS.data.header.map(
        (item) => ({
          ...item,
          align: this.$vuetify.rtl ? "right" : "left",
        })
      );
    },
  },
  methods: {
    getDataFromApi() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;

      const data = {
        asn_number: this.$store.getters.getWMSRECEIVINGSKUS.asn_number,
        page: page,
        per_page: itemsPerPage,
      };

      ApiService.post(this.$store.getters.getWMSRECEIVINGSKUS.url, data)
        .then(({ data }) => {
          this.$store.commit(SET_SKUS_TABLE_DATA, data.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
};
</script>
